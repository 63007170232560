/* Helper Classes */
.right {
  float: right;
}

.left {
  float: left;
}

.white {
  color: white;
}

img.right {
  margin-left: 15px;
  margin-bottom: 8px;
}

img.left {
  margin-right: 15px;
  margin-bottom: 8px;
}

.aright {
  text-align: right;
}

.acenter {
  text-align: center;
}

.aleft {
  text-align: left;
}

.clearboth {
  clear: both;
}

.lt-xs {
  display: none;
}

@media all and (max-width: 991px) {
  .lt-xs {
    display: inherit
  }
  .gt-xs {
    display: none;
  }
}

nav.horizontal ul {
  text-align: center;
}

nav.horizontal li {
  display: inline-block;
  margin: 0;
}

nav.horizontal li:first-child {
  margin-left: 0;
}

nav.horizontal li:last-child {
  margin-right: 0;
}

/* Forms */
form * {
  box-sizing: border-box;
}

form label {
  float: none !important;
  display: block;
  margin-top: 10px;
  font-weight: normal;
}

form input {
  min-width: 50%;
}

form input[type=submit] {
  min-width: 1px;
}

form textarea {
  width: 100%;
}

form input[type=submit], a.button {
  color: black;
  font-weight: bold;
  border: 1px solid black;
  padding: 7px 26px;
  box-shadow: 5px 5px #f9b200;
  &:hover {
    box-shadow: -5px -5px #f9b200;;
    background-color: #000;
    border-color: #000;
    color: white;
  }
}

#anderes {
  position: absolute;
  top: -9999px;
}

/* Subnav */
.verticalnav {
  font-size: 15px;
  padding-top: 20px;
}

.verticalnav ul {
  margin: 0;
  padding: 0;
}

.verticalnav li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.verticalnav a {
  color: #000;
  text-decoration: none;
}

.verticalnav a:hover, .verticalnav .current > a, .verticalnav .section > a {
  color: #f18200;
}

.verticalnav .section > a {
  font-weight: bold;
}

.verticalnav ul ul {
  margin: 20px 0;
}

/* BxSlider overwrites */
#slider {
  margin-bottom: 0;
  height: unset;
  min-height: unset;
}

#slider img {
  max-width: 100%;
}

.bx-viewport {
  border: none;
  box-shadow: none;
  left: 0;
}

.bx-wrapper {
  margin-bottom: 0;
}

.bx-wrapper .slide {
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
}

.bx-wrapper .bx-viewport {
  margin: 0 0 0;
  border-bottom: none;
}

.bx-wrapper .bx-controls-direction {
  display: none;
}

.bx-wrapper .bx-viewport {
  background: none;
  box-shadow: none;
  border: none;
  left: 0;
}

.bx-wrapper .bx-pager {
  bottom: 5px;
  padding: 0;
  float: right;
  width: auto;
  right: 15px;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  width: 8px;
  height: 8px;
  border-radius: 0;
  margin: 0 2px;
  background: #fff;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000;
}

/* Bootstra

/* PageTeasers */
.teaser {
  margin-bottom: 15px;
}

.teaser a {
  display: inline;
}

.teaserborder {
  margin-bottom: 4px;
}

.clear {
  display: none;
}

@media (min-width: 768px) {
  .clear-2 {
    display: block;
    clear: both;
  }
  .clear-3, .clear-4 {
    display: none;
    clear: none;
  }
}

@media (min-width: 992px) {
  .clear-3 {
    display: block;
    clear: both;
  }
  .clear-2, .clear-4 {
    display: none;
    clear: none;
  }
}

/* Homepage Boxes */
.boxes {
  font-size: 13px;
}

.boxes h2 {
  font-size: 14px;
}

.boxes img {
  width: 100%;
}


.home-slider {
  height:90vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width:991px){
    height: 65vh;
  }
  @media (max-width:500px){
    margin-top:-7rem;
    background-size:contain;
    height:64vh;
  }

    img {
      height: auto;
      width: 100%;
    }
}

.home_siematic_stoerer {
    @media (max-width: 600px){
        width: 150px!important;
        margin-left: 10px;
    }
}
